import React from "react";
import Header from "components/Header/Header";
import Top from "components/Top/Top";
import Main from "components/Main/Main";
import TabBar from "components/TabBar/TabBar";

const Home = () => {
  const getCookieValue = (cookieName: string) => {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  };

  const downLoadApp = () => {
    const fbcValue = getCookieValue("_fbc") || "";
    const fbpValue = getCookieValue("_fbp") || "";
    const urlParams = new URLSearchParams(window.location.search);
    const p0 = urlParams.get("p0");
    const p1 = urlParams.get("p1");
    const p2 = urlParams.get("p2");
    const p3 = urlParams.get("p3");
    const p4 = urlParams.get("p4");
    const p5 = urlParams.get("p5");
    const p6 = urlParams.get("p6");
    const fbclid = urlParams.get("fbclid");
    const web = urlParams.get("web");
    const invitecode = urlParams.get("invitecode");
    const webDecode = window.atob(web);
    let src = `https://apk.tbox.live/bigbangrummy_2000_2001.apk`;
    if (fbclid) {
      src = `${webDecode}?af_js_web=true&af_ss_ver=2_7_1&pid=Social_facebook&af_sub1=${fbcValue}&af_sub2=${fbpValue}&af_ss_ui=true`;
    }
    if (web) {
      src = `${webDecode}`
    }
    if (invitecode) {
      src = `${webDecode}?deep_link_sub2=${invitecode}`
    }


    // if (web && invitecode) {
    //   if (web === "aHR0cHM6Ly9iaWdiYW5ncnVtbXkub25lbGluay5tZS9UZ2o4LzdrMjlhZHF5") {
    //     src = `https://bigbangrummy.onelink.me/Tgj8/7k29adqy?deep_link_sub2=${invitecode}`
    //   }
    //   if (web === "aHR0cHM6Ly90ZWVucGF0dGlzdGFyLWluMDMub25lbGluay5tZS9GZXVyL2NrYzZqNmp2") {
    //     src = `https://teenpattistar-in03.onelink.me/Feur/ckc6j6jv?deep_link_sub2=${invitecode}`
    //   }
    // }

    // const src = fbclid
    //   // ? `https://app.adjust.com/${p0}?campaign=${p1}%20({{campaign.id}})&adgroup={{adset.name}}%20({{adset.id}})&creative={{ad.name}}%20({{ad.id}})&redirect=https%3A%2F%2Fapk.tbox.live%2FWagerGame.apk`
    //   ? `${webDecode}?af_js_web=true&af_ss_ver=2_7_1&pid=Social_facebook&af_sub1=${fbcValue}&af_sub2=${fbpValue}&af_ss_ui=true`
    //   : (web ? `${webDecode}` : `https://apk.tbox.live/bigbangrummy_2000_2001.apk`);
    //   // : (invitecode ? `${webDecode}?deep_link_sub2=${invitecode}` : `https://apk.tbox.live/bigbangrummy_2000_2001.apk`);
    const link = document.createElement("a");
    // console.log(src);
    link.href = src;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Header downLoadApp={downLoadApp} />
      <Top downLoadApp={downLoadApp} />
      <Main downLoadApp={downLoadApp} />
      <TabBar downLoadApp={downLoadApp} />
    </div>
  );
};

export default Home;
